export const ipPilotUsers = [
  'volker.ruerup@patent-pilot.com',
  'robert.hollerbach@ip-pilot.com',
  'luka.popovic@narmocode.de',
  'evgeniia.tatarchenkova@ip-pilot.com',
  'evgeniia.tatarchenkova@patent-pilot.com',
  'samantha.mark@ip-pilot.com',
  'vladimir.djuricic@gmail.com',
  'ariel.aziernicki@ip-pilot.com',
  'dunja.ilic@octacode.ba',
  'luka.popovicc@gmail.com',
  'Maddie.Shanahan@spruson.com',
  'rodrigo.oliveira@ip-pilot.com',
  'nonexisting@patent-pilot.com',
  'dustin.frank@ip-pilot.com',
  'jeremie.juste@ip-pilot.com',
  'volker.ruerup@ip-pilot.com',
  'info@ip-lution.com',
  'venkata.peesapati@ip-pilot.com',
  'yallitza.loncharich@ip-pilot.com',
  'christian.mctighe@ip-pilot.com',
  'amirhossein.samiazar@ip-pilot.com',
]

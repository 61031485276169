export const Public = ['hasNameSearch', 'hasLawFirms', 'hasOverviewTab'] as const

export const Free = [
  'hasNameSearch',
  'hasLawFirms',
  'hasApplicants',
  'hasOverviewTab',
  'hasCaseExchangeTab',
  'hasDateRangeSelector',
] as const

export const Basic = [
  'hasNameSearch',
  'hasStandardSearch',
  'hasLawFirms',
  'hasApplicants',
  'hasOverviewTab',
  'hasCaseExchangeTab',
  'hasDateRangeSelector',
  'hasChartFilters',
  'hasSupport',
  'hasAdminSection',
  'hasDataAccess',
  'hasUnconfirmedPermission',
  'hasKpiAccess',
  'showUnconfirmedExternalLinks',
] as const

export const Standard = [
  'hasNameSearch',
  'hasStandardSearch',
  'hasSearchConstraints',
  'hasLocationPermission',
  'hasMapView',
  'hasExtendedSearchResults',
  'showUnconfirmedExternalLinks',
  'hasLawFirms',
  'hasApplicants',
  'hasOverviewTab',
  'hasCaseExchangeTab',
  'hasFilingsTab',
  'hasAttorneysTab',
  'hasRankingInfoTab',
  'hasDateRangeSelector',
  'hasChartFilters',
  'hasPersistentFilters',
  'hasExportPermission',
  'hasEditableProfiles',
  'hasSupport',
  'hasAdminSection',
  'hasDataAccess',
  'hasUnconfirmedPermission',
  'hasKpiAccess',
  'hasFilingCheck',
  'hasNegativeFilters',
  'hasDashboards',
] as const

export const Professional = [
  'hasNameSearch',
  'hasStandardSearch',
  'hasInsightsSearch',
  'hasSearchConstraints',
  'hasLocationPermission',
  'hasMapView',
  'hasExtendedSearchResults',
  'hasLawFirms',
  'hasApplicants',
  'hasOverviewTab',
  'hasCaseExchangeTab',
  'hasFilingsTab',
  'hasAttorneysTab',
  'hasInsightsTab',
  'hasExecutiveSummaryTab',
  'hasRankingInfoTab',
  'hasDateRangeSelector',
  'hasChartFilters',
  'hasPersistentFilters',
  'hasNegativeFilters',
  'hasFilingCheck',
  'hasFamilyCheck',
  'hasSuitabilityCheck',
  'hasInstructionCheck',
  'hasPeerGroupCheck',
  'hasOneOnOneComparison',
  'hasExportPermission',
  'hasEditableProfiles',
  'hasLastSearches',
  'hasSavedSearches',
  'hasDashboards',
  'hasNotes',
  'hasBuildCharts',
  'hasAutomatedReports',
  'hasSupport',
  'hasAdminSection',
  'hasDataAccess',
  'hasUnconfirmedPermission',
  'hasKpiAccess',
  'showUnconfirmedExternalLinks',
  'hasComparison',
  'hasAboutTab',
  'hasSimilarity',
  'hasMutuality',
  'hasCustomCharts',
  'hasInsights',
  'hasTechOverlap',
  'hasFilingsView',
  'hasAttorneysProfile',
  'hasExcelImport',
  'hasEntityGrouping',
  // 'hasPushToCrm',
  'hasCustomView',
  'hasExcelAutofill',
] as const

export const Enterprise = [
  'hasNameSearch',
  'hasStandardSearch',
  'hasInsightsSearch',
  'hasSearchConstraints',
  'hasLocationPermission',
  'hasMapView',
  'hasExtendedSearchResults',
  'hasLawFirms',
  'hasApplicants',
  'hasOverviewTab',
  'hasCaseExchangeTab',
  'hasFilingsTab',
  'hasAttorneysTab',
  'hasInsightsTab',
  'hasExecutiveSummaryTab',
  'hasRankingInfoTab',
  'hasDateRangeSelector',
  'hasChartFilters',
  'hasPersistentFilters',
  'hasNegativeFilters',
  'hasFilingCheck',
  'hasFamilyCheck',
  'hasSuitabilityCheck',
  'hasInstructionCheck',
  'hasPeerGroupCheck',
  'hasOneOnOneComparison',
  'hasExportPermission',
  'hasEditableProfiles',
  'hasLastSearches',
  'hasSavedSearches',
  'hasDashboards',
  'hasNotes',
  'hasBuildCharts',
  'hasAutomatedReports',
  'hasApiAccess',
  'hasIntegration',
  'hasSupport',
  'hasAdminSection',
  'hasDataAccess',
  'hasUnconfirmedPermission',
  'hasKpiAccess',
  'showUnconfirmedExternalLinks',
  'hasComparison',
  'hasAboutTab',
  'hasSimilarity',
  'hasMutuality',
  'hasCustomCharts',
  'hasInsights',
  'hasTechOverlap',
  'hasFilingsView',
  'hasAttorneysProfile',
  'hasExcelImport',
] as const
